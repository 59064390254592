import React from "react";
import "./index.css";

function Navbar2() {
  return (
    <nav className="navbar navbar-expand-lg navbar-light inner-navbar-style1">
      <div className="flex-center">
        <div>H</div>

        <div>
          <ul className="flex-li">
            <li>Home</li>
            <li>Work</li>
            <li>Quote</li>
            <li>Service</li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar2;
