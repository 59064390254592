

import React from 'react'
import Section1 from '../../components/home1/Newhome/Section1'
import Section2 from '../../components/home1/Newhome/Section2'
import Section3 from '../../components/home1/Newhome/Section3'

const Newhome = () => {
  return (
    <div> <div className="font-sans">
    {/* Header */}
    <Section1/>

    {/* Pincode Alert */}
    {/* <Section2/> */}

    {/* Estimate Section */}
    <Section3/>
  </div>
  </div>
  )
}

export default Newhome