import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate


const FloorplanSelection = () => {
  const [selectedFloorplan, setSelectedFloorplan] = useState('');
  const [selectedPurpose, setSelectedPurpose] = useState('');
  const navigate = useNavigate();

  const floorplans = ['1 BHK', '2 BHK', '3 BHK', '3+ BHK'];
  const purposes = ['Move In', 'Rent Out', 'Renovate'];


  useEffect(() => {
    if (selectedFloorplan && selectedPurpose) {

      navigate('/requirement', {
        state: { floorplan: selectedFloorplan, purpose: selectedPurpose }
      });


    }
  }, [selectedFloorplan, selectedPurpose, navigate]);

  return (
    <div>
      <div className="p-8 bg-gray-100 min-h-screen flex items-center justify-center">
        <div className="bg-white rounded-lg shadow-lg p-8 max-w-4xl w-full">
          <h2 className="text-xl font-semibold mb-2 text-center">LET'S GET STARTED</h2>
          <p className="text-sm text-gray-500 text-center mb-8">STEP 2 OF 3</p>

          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Left Side - Floorplan and Purpose */}
            <div className='border-r border-gray-200'>
              <h3 className="text-lg font-medium mb-4 ">Your floorplan</h3>
              <div className="flex flex-wrap gap-4 mb-6">
                {floorplans.map((floorplan) => (
                  <button
                    key={floorplan}
                    className={`py-2 px-6 border-2 rounded-full text-red-500 ${selectedFloorplan === floorplan ? 'border-red-500' : 'border-gray-300'
                      }`}
                    onClick={() => setSelectedFloorplan(floorplan)}
                  >
                    {floorplan}
                  </button>
                ))}
              </div>

              <h3 className="text-lg font-medium mb-4">Purpose</h3>
              <div className="flex flex-wrap gap-4">
                {purposes.map((purpose) => (
                  <button
                    key={purpose}
                    className={`py-2 px-6 border-2 rounded-full text-red-500 ${selectedPurpose === purpose ? 'border-red-500' : 'border-gray-300'
                      }`}
                    onClick={() => setSelectedPurpose(purpose)}
                  >
                    {purpose}
                  </button>
                ))}
              </div>
            </div>

            {/* Right Side - Illustration and Text */}
            <div className="flex flex-col items-center justify-center">
              <img
                src="/home1/assets/img/head.png"
                alt="Building illustration"
                className="h-40 mb-4"
              />
              <h3 className="text-lg font-medium text-center">Your Ideas. Our Expertise.</h3>
              <p className="text-gray-500 text-center">
                Our 600+ design experts use state-of-the-art 3D design technology, SpaceCraft, to ensure that you get the perfect designs for your home. Wait no more! Start your home interiors journey with us.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FloorplanSelection;

