import React from "react";
import { Link } from "react-router-dom";


function Footer() {
  return (
    <footer className="tc-footer-style1">
      <div className="container">
        <div className="top-content section-padding">
          <div className="row gx-0">
            <div className="col-lg-4">
              <div className="info-side">
                <div className="text fsz-24 color-333 lh-3 fw-600">
                  We believe that architecture has the power to shape lives and
                  uplift communities. Gharwala&lsquo;s philosophy is passion for
                  innovation, sustainability, and timeless aesthetics.
                </div>
                <div className="foot-social mt-50">
                  <a href="#">
                    <i className="fab fa-facebook-f"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-instagram"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-linkedin-in"></i>
                  </a>
                  <a href="#">
                    <i className="fab fa-youtube"></i>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-3 offset-lg-2">
              <div className="branch-card">
                <h5 className="mb-20 mt-5 mt-lg-0 fw-600">
                  Kolkata, West Bengal
                </h5>
                <ul className="footer-links">
                  <li>
                    <a href="#">2, New Tollygunge, Purba Putiary, 700093</a>
                  </li>
                  <li>
                    <a href="#">support@interiormaster.in</a>
                  </li>
                  <li>
                    <a href="#">+91 82404 44587</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="branch-card">
                <h5 className="mb-20 mt-[-10px] fw-600">Helps</h5>
                <ul className="footer-links">
                  <li>
                    <a href="#">Terms & Conditions</a>
                  </li>
                  <li>
                    <a href="#">Partner Policy</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="foot">
          <div className="row">
            <div className="col-lg-6">
              <p className="fsz-13">© 2024 Interior Master. All Rights Reserved</p>
            </div>
            <div className="col-lg-6">
              <div className="foot-links mt-4 mt-lg-0 flex gap-4">
                <Link to="/">Home</Link>
                <Link to="/">Blogs</Link>
                <Link to="/">Contact</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
