import React from 'react';

interface ExperienceItemProps {
  title: string;
  company: string;
  startDate: string;
  endDate: string;
}

const ExperienceItem: React.FC<ExperienceItemProps> = ({ title, company, startDate, endDate }) => {
  return (
    <div className="mb-4">
      <div className="flex justify-between items-center">
        <h3 className="text-lg font-semibold text-gray-800">{title}</h3>
        <p className="text-gray-500 text-sm">{startDate} — {endDate}</p>
      </div>
      <p className="text-gray-600">{company}</p>
      <hr className="mt-2" />
    </div>
  );
};

const WorkExperience: React.FC = () => {
  return (
    <div className="max-w-sm p-6 bg-white rounded-lg shadow-md text-gray-800">
      <h4 className="text-sm font-semibold uppercase text-gray-400">Work Experience</h4>

      {/* Experience items */}
      <div className="mt-4">
        <ExperienceItem
          title="Product Designer"
          company="Pioneer"
          startDate="2022"
          endDate="Now"
        />
        <ExperienceItem
          title="Product Designer"
          company="Digital"
          startDate="2020"
          endDate="2022"
        />
        <ExperienceItem
          title="UX/UI Designer"
          company="Digital"
          startDate="2017"
          endDate="2020"
        />
      </div>
    </div>
  );
};

export default WorkExperience;
