import React from 'react';
import { Link } from 'react-router-dom';
import BloggerCard from "../blogs/card3.tsx";
import ArticleCard2 from "../blogs/card2.tsx";
import WorkExperience from "../blogs/card1.tsx";

function BlogPage() {
  const topics = [
    { name: "Technology", color: "text-blue-600" },
    { name: "Travel", color: "text-orange-500" },
    { name: "Sport", color: "text-green-600" },
    { name: "Business", color: "text-purple-600" },
    { name: "Management", color: "text-pink-600" },
    { name: "Trends", color: "text-red-600" },
    { name: "Startups", color: "text-gray-600" },
    { name: "News", color: "text-blue-400" },
  ];
  const articles: Article[] = [
    {
      id: 1,
      category: "SPORT",
      toptitle: "Ethan Caldwell",
      title: "Key Sports Trends for 2024: From AI to Virtual Reality",
      date: "September 24, 2024",
      author: "Ethan Caldwell",
      description:
        "Dive into the key sports trends like AI and virtual reality set to redefine the sports industry in 2024.",
      imageUrl:
        "https://revision.codesupply.co/revision/wp-content/uploads/sites/2/2024/09/demo-image-0038-800x450.webp",
      header:
        "In today’s ever-evolving world, storytelling has become a powerful tool for connection.",
      intro:
        "Revision provides a unique platform for individuals to share their stories.",
      paragraphs: [
        "Revision is more than a typical content hub. It's a dynamic space for meaningful conversations and personal stories...",
        "With categories covering everything from love and relationships to personal development and lifestyle...",
      ],
      sections: [
        {
          heading: "Stories that Matter",
          content: [
            "At the core of Revision is a commitment to delivering stories that matter...",
            "Author Profiles: Each contributor has a detailed profile...",
            "Experience Widgets: Contributors showcase their professional work...",
          ],
        },

        {
          heading: "How do I create meaningful connections?",
          content: [
            "When producing content for platforms like Revision, it’s essential to focus...",
            "There are several ways to ensure your content builds these connections...",
          ],
        },
      ],
      image: "",
    },
    {
      id: 2,
      category: "TECHNOLOGY",
      toptitle: "Ethan Cathdell",
      title: "The Impact of Automation on Business Management Efficiency",
      date: "September 20, 2024",
      author: "Ethan Caldwell",
      description:
        "Learn how automation is boosting business management efficiency and driving growth in various sectors.",
      imageUrl:
        "https://revision.codesupply.co/revision/wp-content/uploads/sites/2/2024/09/demo-image-0037-800x450.webp",
      header: "How to foster authentic engagement?",
      intro: "Fostering engagement is essential to making connections last.",
      paragraphs: [
        "Engagement goes beyond just interaction; it's about creating meaningful and lasting connections.",
        "Authenticity plays a significant role in building long-term loyalty with audiences...",
      ],
      sections: [
        {
          heading: "Keys to Authentic Engagement",
          content: [
            "Engage with empathy by understanding your audience's needs...",
            "Build relationships based on mutual trust and respect...",
          ],
        },
      ],
      image: "",
    },
    {
      id: 3,
      category: "SPORT",
      toptitle: "Ethan Cathdell",
      title: "Startups Disrupting the Sports Industry with Innovative Tech",
      date: "September 18, 2024",
      author: "Ethan Caldwell",
      description:
        "Discover how startups are leveraging technology to disrupt and innovate within the sports industry.",
      imageUrl:
        "https://revision.codesupply.co/revision/wp-content/uploads/sites/2/2024/09/demo-image-0036-800x450.webp",
      header: "Revolutionizing Sports",
      intro:
        "Startups are taking sports to the next level with cutting-edge technology.",
      paragraphs: [
        "New tech-driven innovations are transforming how sports are played, analyzed, and consumed.",
        "From AI-powered performance analytics to virtual reality training, these advancements are shaping the future of sports.",
      ],
      sections: [
        {
          heading: "Tech in Sports",
          content: [
            "AI is now widely used to improve athletes' performance by offering precise, data-driven insights.",
            "Virtual reality allows teams to simulate high-pressure game situations for better training.",
          ],
        },
      ],
      image: "",
    },
    {
      id: 4,
      category: "NEWS",
      toptitle: "Ethan Cathdell",
      title: "Travel Trends in 2024: Virtual Tours and Immersive Experiences",
      date: "September 16, 2024",
      author: "Ethan Caldwell",
      description:
        "Explore the rise of virtual tours and immersive experiences shaping the future of travel in 2024.",
      imageUrl:
        "https://revision.codesupply.co/revision/wp-content/uploads/sites/2/2024/09/demo-image-0035-800x450.webp",
      header: "The Future of Travel",
      intro:
        "2024 brings new trends to travel, with virtual tours and immersive experiences leading the way.",
      paragraphs: [
        "As technology advances, travelers can now experience destinations from the comfort of their homes.",
        "Virtual tours are offering unique ways to explore the world and prepare for future adventures.",
      ],
      sections: [
        {
          heading: "Immersive Travel",
          content: [
            "Immersive experiences are enabling travelers to step into different worlds through augmented and virtual reality.",
            "Tour operators are incorporating 360-degree tours to enhance pre-trip research and planning.",
          ],
        },
      ],
      image: "",
    },
    {
      id: 5,
      category: "TRENDS",
      toptitle: "Ethan Cathdell",
      title: "Why Data Security is a Priority for Business Management in 2024",
      date: "September 5, 2024",
      author: "Ethan Caldwell",
      description:
        "Understanding why data security is a growing concern for business management in today’s digital world.",
      imageUrl:
        "https://revision.codesupply.co/revision/wp-content/uploads/sites/2/2024/09/demo-image-0034-800x450.webp",
      header: "Data Security in 2024",
      intro:
        "Businesses are now prioritizing data security more than ever before.",
      paragraphs: [
        "With growing cyber threats, companies must focus on protecting sensitive data.",
        "Data breaches can have devastating effects on business operations, making security a top priority.",
      ],
      sections: [
        {
          heading: "Protecting Your Data",
          content: [
            "Strong encryption and secure authentication methods are essential for data protection.",
            "Businesses must invest in advanced cybersecurity measures to mitigate risks.",
          ],
        },
      ],
      image: "",
    },
    {
      id: 6,
      category: "STARTUPS",
      toptitle: "Ethan Cathdell",
      title: "Startups and AI: How Artificial Intelligence Drives Innovation",
      date: "August 30, 2024",
      author: "Ethan Caldwell",
      description:
        "See how startups are harnessing the power of AI to foster innovation and reshape industries.",
      imageUrl:
        "https://revision.codesupply.co/revision/wp-content/uploads/sites/2/2024/09/demo-image-0033-800x450.webp",
      header: "AI at the Core of Innovation",
      intro:
        "Artificial Intelligence is at the heart of many startup innovations, driving efficiency and creativity.",
      paragraphs: [
        "AI is transforming industries by automating tasks and enabling better decision-making.",
        "Startups are using AI to build smarter products, from personalized recommendations to predictive analytics.",
      ],
      sections: [
        {
          heading: "AI for Startups",
          content: [
            "AI can help startups reduce operational costs by automating routine tasks.",
            "Machine learning models are enabling startups to provide more personalized services to their customers.",
          ],
        },
      ],
      image: "",
    },
  ];

  const createSlug = (title: string): string => {
    return title
      .toLowerCase()

      .replace(/[^a-z0-9 -]/g, "")
      .replace(/\s+/g, "-")
      .replace(/-+/g, "-");
  };

  const ArticleCard: React.FC<{ article: Article }> = ({ article }) => (
    <div className="bg-transparent mb-6 flex flex-col sm:flex-row border-b border-slate-300 py-4">
      <div className="relative mb-4 sm:mb-0 sm:mr-6 w-full sm:w-[50%] h-64">
        {/* Ensure the image size is fixed */}
        <div className="absolute top-5 left-4 rounded-md py-1 px-1 text-sm bg-white font-semibold text-gray-500 z-10">
          {article.category}
        </div>
        <img
          className="w-full h-full object-cover rounded-xl" // Ensure the image keeps the same size
          src={article.imageUrl}
          alt={`${article.title} image`}
          width={640}
          height={256}
          layout="responsive"
        />
      </div>

      <div className="flex-1 overflow-x-visible">
        <h6 className="text-slate-500 font-semibold text-xs">
          {article.author} on {article.date}
        </h6>
        <h3 className="mt-2 text-lg font-bold text-[rgb(41,41,75)]">
          {article.title}
        </h3>
        <p className="mt-2 text-sm text-gray-400">{article.description}</p>
        <div className="mt-4">
          <Link href={`/blogs/${createSlug(article.title)}`}>
            <button className="bg-gradient-to-r from-indigo-400 to-indigo-600 text-white font-semibold py-2 px-4 rounded-lg shadow-md hover:from-indigo-500 hover:to-indigo-700 transition-colors duration-300">
              Discover More
            </button>
          </Link>
        </div>
      </div>
    </div>
  );

  return (
    <>
      <div className="px-8">
        {/* header section */}
        <div className="bg-white px-4 py-12 border-b border-slate-300">
          <div className="text-center">
            <h1 className="text-6xl font-bold text-[rgb(41,41,75)]">
              Heartfelt <span className="text-indigo-500">Reflections</span>:
              Stories of Love,
              <br /> Loss, and Growth
            </h1>
            <p className="mt-4 text-lg text-[rgb(105,105,129)]">
              Revision welcomes you to the ultimate source for fresh
              perspectives! Explore <br />
              curated content to enlighten, entertain, and engage global
              readers.
            </p>
          </div>
        </div>

        {/* explore tabs part */}
        <div className="bg-white py-12 px-40">
          <div className="text-center">
            <h2 className="text-sm font-semibold text-gray-500">
              Explore Trending Topics
            </h2>
            <div className="mt-6 flex justify-center flex-wrap gap-6">
              {topics.map((topic) => (
                <div
                  key={topic.name}
                  className="flex items-center space-x-2 bg-white rounded-full shadow-[0_4px_10px_rgba(99,102,241,0.2)] px-6 py-3 hover:shadow-[rgba(99,102,241,0.4)] cursor-pointer transition"
                >
                  <span className={`text-2xl ${topic.color}`}></span>
                  <span className="text-lg font-medium text-gray-700">
                    {topic.name}
                  </span>
                </div>
              ))}
            </div>
          </div>
        </div>

        {/* articles section */}
        <div className="flex py-4 sm:px-6 lg:px-4 w-full">
          {/* Left side: Articles */}
          <div className="w-full md:w-[120%]">
            {" "}
            {/* Increased width of the left side */}
            <div className="max-w-full mx-auto">
              {articles.map((article) => (
                <ArticleCard key={article.id} article={article} />
              ))}
            </div>
          </div>

          {/* Right side: BloggerCard */}
          <div className="hidden md:block md:w-[40%] ml-8">
            {" "}
            {/* Reduced the margin */}
            <div>
              <BloggerCard />
            </div>
            <div className="mt-10">
              <ArticleCard2
                category="Management"
                author="Ethan Caldwell"
                date="July 7, 2024"
                title="AI in Business Management: Improving Efficiency and Decision Making"
                imageUrl="https://revision.codesupply.co/revision/wp-content/uploads/sites/2/2024/09/demo-image-0021-768x432.webp"
              />
            </div>
            <div className="mt-10">
              <WorkExperience />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogPage;
