import React from "react";
import { Link } from "react-router-dom";
function Navbar() {
  const handleNavigation = () => {
    window.location.href = "/floorplan"; // This will reload the page
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-dark tc-navbar-style1 section-padding-x">
      <div className="container-fluid content d-flex justify-content-between align-items-center">
        <a className="bg-white hidden md:block  rounded-md px-[10px]" href="/">
          <img src="/im-long.png" alt="" className="rounded w-[170px] h-[50px]" />
          
        </a>

        <a className="bg-white  md:hidden  rounded-md " href="/">
          <img src="/im.png" alt="" className="rounded w-[70px] h-[50px]" />
          
        </a>
   
      
        {/* Get Free Estimate Button */}
        <div className="ms-3">
          <Link
            to="/pincode"
            className="butn border rounded-pill bg-white"
          >
            <span>Free Estimate</span>
          </Link>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
