import React from 'react';
import { FaMapMarkerAlt, FaTwitter, FaFacebookF, FaInstagram, FaLinkedinIn } from 'react-icons/fa';

const BloggerCard: React.FC = () => {
  return (
    <div className="max-w-xs p-6 bg-white rounded-lg shadow-md text-gray-800">
      <h4 className="text-sm font-semibold uppercase text-gray-400">About</h4>
      
      <div className="mt-4 flex items-center">
        {/* Profile image */}
        <div className="w-12 h-12 overflow-hidden rounded-full bg-red-500">
          <img
            src="https://revision.codesupply.co/revision/wp-content/uploads/sites/2/2024/10/Ethan-Caldwell.webp"
            alt="Ethan Caldwell"
            className="w-full h-full object-cover"
          />
        </div>

        {/* Blogger info */}
        <div className="ml-4">
          <h2 className="text-lg font-semibold">Ethan Caldwell</h2>
          <p className="text-sm text-gray-500">Reflective Blogger</p>
        </div>
      </div>

      {/* Description */}
      <p className="mt-4 text-gray-400 text-sm">
        Ethan Caldwell shares thoughtful insights and reflections on life, culture, and personal growth. 
        His work explores the intersections of creativity and experience, offering readers unique perspectives.
      </p>

      {/* Location */}
      <div className="flex items-center mt-4 text-gray-500">
        <FaMapMarkerAlt className="mr-2" />
        <p>Paris, France</p>
      </div>

      {/* Social Media Icons */}
      <div className="flex mt-4 space-x-4 text-gray-600">
        <FaTwitter className="cursor-pointer hover:text-blue-500" />
        <FaFacebookF className="cursor-pointer hover:text-blue-600" />
        <FaInstagram className="cursor-pointer hover:text-pink-500" />
        <FaLinkedinIn className="cursor-pointer hover:text-blue-700" />
      </div>
    </div>
  );
};

export default BloggerCard;
