import React from 'react';

const Design = () => {
  const designData = [
    { name: "Modular Kitchen", imageUrl: "https://res.cloudinary.com/dtbvpj2r6/image/upload/v1733284672/Kitchen%20Fully%20Modular/r9ffuwgxyawltn0tflyb.jpg" },
    { name: "Bedroom", imageUrl: "https://res.cloudinary.com/dtbvpj2r6/image/upload/v1733284673/Master%20Bedroom/jnpsgibilnhjg0qc9cmk.jpg" },
    { name: "Living Room", imageUrl: "	https://res.cloudinary.com/dtbvpj2r6/image/upload/v1733284664/Master%20Bedroom/a3r9qtyypuxmhv01eiti.jpg" },
    { name: "Bathroom", imageUrl: "	https://res.cloudinary.com/dtbvpj2r6/image/upload/v1733284591/Bathroom%20Design/ac68rubibkogkhxfaxtz.jpg" },
    { name: "Kids Bedroom", imageUrl: "https://res.cloudinary.com/dtbvpj2r6/image/upload/v1733288852/Kids%20Bedroom/yfuhhuwrlmanzbddzyl0.jpg" },
    { name: "False Ceiling", imageUrl: "https://res.cloudinary.com/dtbvpj2r6/image/upload/v1733284680/False%20Cieing%20Design/p7rrcdupezkyz8ikffhg.jpg" },
  ];

  return (
    <div className="p-6 bg-gray-50 mb-32">
      <h2 className="text-3xl font-bold mb-2 pt-32 text-left  md:text-center">Design Gallery</h2>
      <p className="text-gray-600 mb-12 text-left md:text-center">
        If you're on the lookout for simple home interior designs, look no further than our end-to-end design services.
      </p>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {designData.map((design, index) => (
          <div key={index} className="relative overflow-hidden rounded-lg shadow-lg cursor-pointer hover:scale-[1.05] hover:duration-[1.25s]">
            <img src={design.imageUrl} alt={design.name} className="object-cover w-full h-64" />
            <div className="absolute bottom-0 left-0 w-full bg-transparent bg-opacity-50 p-4">
              <h3 className="text-lg font-semibold text-white">{design.name}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Design;
