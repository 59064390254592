import React from "react";

const Section3 = () => {
  const estimates = [
    {
      title: "Essential Interiors",
      description: "For homes to be rented out",
      price: "₹5.1 Lakh",
      items: [
        { name: "Kitchen", cost: "₹1.6 Lakh" },
        { name: "Wardrobe", cost: "₹84,892" },
        { name: "Other Interiors", cost: "₹2.7 Lakh" },
      ],
      popular: false,
    },
    {
      title: "Comfort Interiors",
      description: "For first-time homeowners",
      price: "₹7.5 Lakh",
      items: [
        { name: "Kitchen", cost: "₹2.3 Lakh" },
        { name: "Wardrobe", cost: "₹1.1 Lakh" },
        { name: "Other Interiors", cost: "₹4.2 Lakh" },
      ],
      popular: true, // This will show the POPULAR badge
    },
    {
      title: "Luxury Interiors",
      description: "Best of design and style",
      price: "₹8.8 Lakh",
      items: [
        { name: "Kitchen", cost: "₹2.8 Lakh" },
        { name: "Wardrobe", cost: "₹1.3 Lakh" },
        { name: "Other Interiors", cost: "₹4.7 Lakh" },
      ],
      popular: false,
    },
  ];

  return (
    <section className="container mx-auto px-6 py-8">
      <div className="bg-white border border-gray-300 py-4 px-6 rounded-lg mb-8 flex items-center justify-between">
        <p className="text-gray-700 font-semibold">
          What's Included{" "}
          <span className="text-gray-400 font-normal">
            (Get a price closer to your requirement, by changing the details)
          </span>
        </p>
        <p className="text-red-500 flex items-center space-x-2">
          <span>Kitchen, 1 Bedrooms, 7 Other Interiors</span>
          <span className="text-xl px-2 py-1 font-bold rounded-full border border-red-500 text-red-500 bg-white shadow-md hover:bg-red-500 hover:text-white transition-colors duration-300">
            +
          </span>
        </p>
      </div>

      {/* Dynamic Estimate Cards */}
      <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
        {estimates.map((estimate, index) => (
          <div key={index} className="bg-white p-6 shadow-lg rounded-lg">
            <div className="flex justify-between items-center">
              <h3 className="text-xl font-bold">{estimate.title}</h3>
              {estimate.popular && (
                <span className="px-2 py-1 bg-red-500 text-white text-xs rounded-md">
                  POPULAR
                </span>
              )}
            </div>
            <p className="text-gray-500">{estimate.description}</p>
            <p className="text-red-600 text-3xl font-semibold">
              {estimate.price}
            </p>
            <ul className="space-y-2 my-4">
              {estimate.items.map((item, idx) => (
                <li key={idx} className="flex justify-between">
                  <span>{item.name}</span>
                  <span>{item.cost}</span>
                </li>
              ))}
            </ul>
            <a href="#" className="text-red-500 hover:underline">
              View Details
            </a>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Section3;
