import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Pincode = () => {
  const [pincode, setPincode] = useState('');
  const navigate = useNavigate(); // Use the useNavigate hook for navigation

  const handleChange = (e) => {
    setPincode(e.target.value);
  };

  const handleNext = () => {
    console.log('Pincode submitted:', pincode);
    navigate('/floorplan'); // Use navigate to redirect to the next page
  };

  return (
    <div className="p-8 bg-gradient-to-br from-gray-100 to-white min-h-screen flex items-center justify-center">
      <div className="bg-white rounded-lg shadow-lg p-8 max-w-lg w-full transition-transform transform hover:scale-105 duration-300">
        <h2 className="text-3xl font-semibold mb-4 text-center text-red-600">LET'S GET STARTED</h2>
        <p className="text-sm text-gray-500 text-center mb-8">STEP 1 OF 3</p>

        {/* Input Section for Pincode */}
        <div className="mb-6">
          <label htmlFor="pincode" className="block text-lg font-medium mb-2 text-gray-700">Enter Your Pincode</label>
          <input
            type="text"
            id="pincode"
            value={pincode}
            onChange={handleChange}
            className="border border-gray-300 rounded-lg py-3 px-4 w-full focus:outline-none focus:ring-2 focus:ring-red-500 transition duration-200 ease-in-out shadow-md"
            placeholder="e.g., 700001"
          />
        </div>

        {/* Illustration and Text */}
        <div className="flex flex-col items-center justify-center mb-8">
          <img
            src="/home1/assets/img/head.png"
            alt="Building illustration"
            className="h-32 mb-4 rounded-md shadow-lg transition-transform transform hover:scale-110 duration-300"
          />
          <h3 className="text-lg font-medium text-center text-gray-800">Your Ideas. Our Expertise.</h3>
          <p className="text-gray-600 text-center mt-2">
            Our 600+ design experts use state-of-the-art 3D design technology, SpaceCraft, to ensure that you get the perfect designs for your home. Wait no more! Start your home interiors journey with us.
          </p>
        </div>

        {/* Next Button */}
        <button
          onClick={handleNext}
          className="w-full py-3 bg-red-600 text-white rounded-lg shadow-lg hover:bg-red-700 transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-opacity-50"
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Pincode;

